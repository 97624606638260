import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Image from '@components/Image';

import * as styled from './styles';

function HeroWide({
  description,
  heading,
  imagesExtended = [],
}) {
  if (imagesExtended[1]) {
    imagesExtended[1].image.asset.fluid.sizes = '(max-width: 1200px) 40vw, 20vw';
  }

  return (
    <styled.Wrapper
      noImages={!imagesExtended.length}
    >
      <styled.Inner
        noImages={!imagesExtended.length}
      >
        <styled.Content>
          <styled.Heading level={100}>
            {heading}
          </styled.Heading>
          <styled.Description level={300}>
            {description}
          </styled.Description>
        </styled.Content>
        {imagesExtended.length > 0 && (
          <styled.Images>
            {imagesExtended[0] && (
              <styled.ImageWrapper
                onClick={imagesExtended[0].videoUrl}
                data-has-video={!!imagesExtended[0].videoUrl}
              >
                <Image
                  fluid={get(imagesExtended[0], 'image.asset.fluid')}
                  ratio={9 / 16}
                  {...imagesExtended[0]}
                />
                {imagesExtended[0].credit && (
                  <span>{imagesExtended[0].credit}</span>
                )}
              </styled.ImageWrapper>
              )}
          </styled.Images>
        )}
      </styled.Inner>
    </styled.Wrapper>
  );
}

HeroWide.propTypes = {
  isWideHero: PropTypes.bool,
  description: PropTypes.string,
  heading: PropTypes.string.isRequired,
  imagesExtended: PropTypes.arrayOf(PropTypes.object),
};

export default HeroWide;
