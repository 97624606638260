import styled from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';

import { Heading as UnstyledHeading } from '@components/type';

export const Content = styled.div`
  position: absolute;
  padding: 30px;
  z-index: 1;
  text-align: center;
  width: 100%;

  ${mq.gtms`
      padding: 80px;
  `}
`;

export const Description = styled(UnstyledHeading)`
  margin-bottom: 40px;
  color: ${colors.N0};
  text-transform: uppercase;
  text-decoration: underline;
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 8px;
  color: ${colors.N0};
  text-transform: uppercase;
  width: 100%;
`;

export const Images = styled.div`
  position: relative;

  img {
    filter: brightness(70%) contrast(120%);
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
`;

export const Inner = styled.div`
  position: relative;
  padding: 24px 0 0;
  z-index: 1;

  ${mq.gtms`
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    padding: 120px 0 0;
  `}
`;

export const Wrapper = styled.div`
  position: relative;
  padding-top: 48px;
`;
