import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import blockFactory from '@utils/blockFactory';
import getLocalizedData from '@utils/localize';
import { TranslationContext } from '@utils/useTranslations';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import Navigation from '@components/Navigation';
import SEO from '@components/SEO';
import Space from '@components/Space';
import propsTransformer from '../../utils/propsTransformer';
import HeroWide from '../../components/HeroWide';

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      route {
        title {
          en
          vi
          zh_Hans
          _type
        }
        openGraph {
          keywords {
            en
            vi
            zh_Hans
            _type
          }
          description {
            en
            vi
            zh_Hans
            _type
          }
          image {
            asset {
              fixed(width: 1200) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
      published_vi
      published_zh_Hans
      hero {
        isWideHero
        button {
          label {
            en
            vi
            zh_Hans
            _type
          }
          link
          route {
            slug {
              current
            }
          }
        }
        breadcrumb {
          self {
            en
            vi
            zh_Hans
            _type
          }
          grandparent {
            title {
              en
              vi
              zh_Hans
              _type
            }
            slug {
              current
            }
          }
          sanityParent {
            title {
              en
              vi
              zh_Hans
              _type
            }
            slug {
              current
            }
          }
        }
        heading {
          en
          vi
          zh_Hans
          _type
        }
        headingAlignment
        description {
          en
          vi
          zh_Hans
          _type
        }
        imagesExtended {
          credit
          link
          route {
            title {
              _type
              en
              vi
              zh_Hans
            }
            slug {
              current
            }
          }
          altText {
            en
            vi
            zh_Hans
            _type
          }
          image  {
            asset {
              fluid(
                maxWidth: 800,
                sizes: "(max-width: 600px) 100vw, 50vw"
                ){
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        imagesFlipped
        theme
      }
      showChat
      isExcludedFromSearch
      _rawContent(resolveReferences: { maxDepth: 12 })
    }
  }
`;

const PageTemplate = (props) => {
  const { data: rawData } = props;
  const intl = useIntl();
  const data = getLocalizedData(
    rawData,
    get(rawData, `page.published_${intl.locale}`) ? intl.locale : 'en',
  );
  const pageData = get(data, 'page', {});

  const translationContext = {
    vi: get(rawData, 'page.published_vi'),
    zh_Hans: get(rawData, 'page.published_zh_Hans'),
  };

  return (
    <>
      <TranslationContext.Provider value={translationContext}>
        <Navigation banner={props.banner} navItems={props.navItems} />
        <main>
          <SEO
            description={get(pageData, 'route.openGraph.description')}
            image={get(pageData, 'route.openGraph.image.asset.fixed')}
            keywords={pageData.route.openGraph?.keywords}
            title={pageData.route.title}
            noIndex={pageData.isExcludedFromSearch}
          />
          {pageData.hero && (
            <>
              {pageData.hero.isWideHero ? <HeroWide {...propsTransformer(pageData.hero)} /> : <Hero {...propsTransformer(pageData.hero)} />}
              <Space sizes={['xsmall', 'small']} />
            </>
          )}
          {(pageData._rawContent || []).map((block, idx) => (
            <Fragment key={idx}>
              {blockFactory(block, null, intl.locale)}
            </Fragment>
          ))}
        </main>
        {/*<Space sizes={['xsmall', 'small']} />*/}
        <Footer showChat={pageData.showChat} />
      </TranslationContext.Provider>
    </>
  );
};

export default PageTemplate;
